export enum EStatus {
  ACTIVATED = 'activated',
  PAUSED = 'paused',
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  ACCEPTED = 'accepted',
  CANCELED = 'canceled',
  PAID = 'paid',
  FREE = 'free',
  FREE_PERIOD = 'free_period',
  RETURNED = 'returned',
  REJECTED = 'rejected',
  IN_PREPARATION = 'in_preparation',
  SHIPPED = 'shipped',
}

export type StatusType<Status extends Partial<EStatus>> = {
  [key in Status]: Status;
};

export type OrderStatusType = keyof StatusType<
  | EStatus.IN_PROGRESS
  | EStatus.IN_PREPARATION
  | EStatus.SHIPPED
  | EStatus.PENDING
  | EStatus.COMPLETED
  | EStatus.CANCELED
>;

export type GiftStatusType = keyof StatusType<
  EStatus.PENDING | EStatus.ACCEPTED | EStatus.CANCELED
>;

export type ClaimStatusType = keyof StatusType<
  | EStatus.IN_PROGRESS
  | EStatus.IN_PREPARATION
  | EStatus.SHIPPED
  | EStatus.COMPLETED
  | EStatus.CONFIRMED
  | EStatus.CANCELED
>;

export type InvoiceStatusType = keyof StatusType<
  EStatus.PAID | EStatus.FREE | EStatus.FREE_PERIOD | EStatus.PENDING
>;

export type LabelAutoSavingsStatusType = keyof StatusType<EStatus.ACTIVATED | EStatus.PAUSED>;
