import { useCallback } from 'react';

import { useStoreForm } from '@core/context';
import { ErrorHandled } from '@core/type';
import { FormHook, FormState, HookStoreFn } from '@core/type/context';

export const useForm: HookStoreFn<FormState, FormHook> = (): FormHook => {
  const [isLoading, setIsLoading] = useStoreForm<boolean>('isLoading');
  const [error, setError] = useStoreForm<ErrorHandled>('error');

  const clearError = useCallback(() => {
    setError(null);
  }, [setError]);

  return {
    error,
    setError,
    clearError,
    isLoading,
    setIsLoading,
  };
};
