export enum EDateFormat {
  ddMMyyyy = 'ddMMyyyy',
  ddMM = 'ddMM',
  ddMMyyyyhhmm = 'ddMMyyyyhhmm',
  yMd = 'yMd',
  hhmm = 'hhmm',
  ddMMyyyyhhmm24 = 'ddMMyyyyhhmm24',
  MMyyyy = 'MMyyyy',
  MMMMyyyy = 'MMMMyyyy',
  mmYYYY = 'mmYYYY',
}

export enum EDateTimezone {
  EUROPE_PARIS = 'Europe/Paris',
}

export interface DateForm {
  day: string;
  month: string;
  year: string;
}

// ISO 8601 format
export type Datetime = string;
