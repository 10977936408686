import { EColor, ELabel, EStatus } from '@core/type';

export const labelColors = {
  [ELabel.PREORDER]: EColor.DANGER,
  [ELabel.STORAGE_ONLY]: EColor.PRIMARY,
  [EStatus.PAUSED]: EColor.WARNING,
  [EStatus.ACTIVATED]: EColor.SUCCESS,
  [EStatus.PAID]: EColor.SUCCESS,
  [EStatus.PENDING]: EColor.WARNING,
  [EStatus.IN_PROGRESS]: EColor.SUCCESS,
  [EStatus.COMPLETED]: EColor.SUCCESS,
  [EStatus.CONFIRMED]: EColor.SUCCESS,
  [EStatus.ACCEPTED]: EColor.SUCCESS,
  [EStatus.CANCELED]: EColor.DANGER,
  [EStatus.FREE]: EColor.WARNING,
  [EStatus.FREE_PERIOD]: EColor.WARNING,
  [EStatus.RETURNED]: EColor.DANGER,
  [EStatus.REJECTED]: EColor.DANGER,
  [EStatus.IN_PREPARATION]: EColor.SUCCESS,
  [EStatus.SHIPPED]: EColor.SUCCESS,
};
