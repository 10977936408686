import { ColorType, EColor, EColorName } from './color';
import { ThemePalette } from './interface-theme-palette';
import { ESize, SizeType } from './size';

export enum ELabel {
  PREORDER = 'preorder',
  STORAGE_ONLY = 'storageOnly',
  NEW = 'new',
  TOP = 'top',
}

export type LabelType<T extends Partial<ELabel>> = {
  [key in T]: T;
};

export type LabelSize = keyof SizeType<ESize.SM | ESize.MD | ESize.LG>;

export type LabelColorType = keyof ColorType<
  EColor.PRIMARY | EColor.SUCCESS | EColor.WARNING | EColor.DANGER | EColor.ACCENT
>;

export type LabelColorProps = {
  color: EColorName;
  backgroundColor: EColorName;
};

export type LabelColorHandler = (props: {
  color: LabelColorType;
  palette: ThemePalette;
}) => LabelColorProps;

export type LabelStateType = keyof LabelType<ELabel.PREORDER | ELabel.STORAGE_ONLY>;

export type LabelStatusSize = keyof SizeType<ESize.MD | ESize.LG>;

export type LabelProductStatusType = keyof LabelType<ELabel.NEW | ELabel.TOP>;
