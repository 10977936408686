import { Controller } from 'react-hook-form';

import { SelectChangeEvent } from '@core/type';

import { SelectText } from '../../select/SelectText';
import { SelectTextControlProps } from './interface-form-select';

export const SelectTextControl = ({
  name,
  control,
  onChange,
  ...inputProps
}: SelectTextControlProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => {
      const handleChange = (e: SelectChangeEvent<string>) => {
        onChange?.(e);
        field.onChange(e);
      };
      return (
        <SelectText field={field} {...inputProps} onChange={handleChange} fieldState={fieldState} />
      );
    }}
  />
);
