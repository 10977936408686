import { Translate } from 'next-translate';
import { useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { SchemaOf } from 'yup';

type FieldErrorForm = FieldError & { path: string };

// eslint-disable-next-line @typescript-eslint/ban-types
export function useValidator<T extends {}>(validationSchema: SchemaOf<T>, t: Translate) {
  return useCallback(
    async (data: T) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-type-assertion
          errors: (errors as any).inner.reduce(
            (allErrors: FieldErrorForm[], currentError: FieldErrorForm) => {
              // special case when the field is an array and we use useFieldArray()
              if (currentError.path.includes('[')) {
                return {
                  ...allErrors,

                  [currentError.path]: {
                    type: currentError.type ?? 'validation',
                    message:
                      t(
                        `${currentError.path.substring(
                          0,
                          currentError.path.indexOf('['),
                        )}${currentError.path.substring(
                          currentError.path.indexOf(']') + 1,
                        )}.validate.${currentError.type}`,
                      ) ?? currentError.message,
                  },
                };
              }
              return {
                ...allErrors,

                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message:
                    t(`${currentError.path}.validate.${currentError.type}`) ?? currentError.message,
                },
              };
            },
            {},
          ),
        };
      }
    },
    [validationSchema, t],
  );
}
