import { FC, ForwardedRef, forwardRef, memo, ReactNode, Ref } from 'react';

import { useContextSelect } from '@core/context';
import { EColor, EPosition, ESize, InputSize } from '@core/type';

import { InputBorder, InputLabel } from '../input';
import { Box } from '../layout';
import { Typography } from '../typography';
import { SelectBase } from './SelectBase';
import { SelectBaseContent } from './SelectBaseContent';
import { SelectBaseWrapper } from './SelectBaseWrapper';
import { SelectOption } from './SelectOption';
import {
  SelectBaseContentProps,
  SelectBaseWrapperProps,
  SelectTextProps,
  SelectTextWrapperProps,
} from './interface-select';

const SelectTextWrapper = memo(
  ({
    children,
    isSuccess,
    isError,
    label,
    id,
    required,
    size = ESize.MD,
    withValue,
    borderColor,
    ...restProps
  }: SelectTextWrapperProps) => {
    const { isOpen } = useContextSelect();

    return (
      <SelectBaseWrapper size={size} padding={'0px 16px'} {...restProps}>
        <InputBorder
          borderColor={borderColor}
          isError={isError}
          isSuccess={isSuccess}
          isFocus={isOpen}
        />
        {label && (
          <InputLabel
            htmlFor={id}
            isRequired={required}
            size={size as InputSize}
            withValue={withValue}
          >
            {label}
          </InputLabel>
        )}

        {children}
      </SelectBaseWrapper>
    );
  },
);

SelectTextWrapper.displayName = 'SelectTextWrapper';

const SelectTextPlaceholder = ({ children }: { children: ReactNode }) => (
  <Typography color={EColor.SECONDARY} lineHeight={'16px'} fontSize={'16px'}>
    {children}
  </Typography>
);

const Wrapper = ({
  children,
  isError: defaultIsError,
  isSuccess,
  value,
  fieldState,
  field,
  required,
  label,
  isSubmitted,
  id,
  ...restProps
}: SelectBaseWrapperProps) => {
  const currentValue = field?.value || value;
  const isError = defaultIsError
    ? defaultIsError
    : !!fieldState?.error && (fieldState?.isTouched || isSubmitted);

  return (
    <SelectTextWrapper
      id={id}
      label={label}
      isError={isError}
      isSuccess={isSuccess === undefined ? !!currentValue : isSuccess}
      withValue={!!currentValue}
      required={required}
      {...restProps}
    >
      {children}
    </SelectTextWrapper>
  );
};

const SelectTextContainer = (
  {
    value,
    fieldState,
    field,
    required,
    label,
    isSubmitted,
    placeholder,
    ...restProps
  }: SelectTextProps,
  ref: Ref<HTMLInputElement>,
) => {
  const fieldValue = field?.value ?? value ?? '';

  const Content: FC<SelectBaseContentProps> = ({ children, ...restComponentProps }) => (
    <SelectBaseContent
      position="relative"
      top={label && fieldValue ? '6px' : '0px'}
      maxWidth={'calc(100% - 40px)'}
      whiteSpace="nowrap"
      {...restComponentProps}
    >
      <Box overflow="hidden" textOverflow="ellipsis">
        {children}
      </Box>
    </SelectBaseContent>
  );

  return (
    <SelectBase
      ref={ref}
      value={fieldValue}
      isSubmitted={isSubmitted}
      field={field}
      fieldState={fieldState}
      label={label}
      required={required}
      placeholder={
        placeholder && !label && <SelectTextPlaceholder>{placeholder}</SelectTextPlaceholder>
      }
      Option={SelectOption}
      Wrapper={Wrapper}
      Content={Content}
      iconProps={{
        size: ESize.MD,
      }}
      verticalPositionProps={{
        [EPosition.TOP]: {
          top: 'auto',
          bottom: '46px',
        },
        [EPosition.BOTTOM]: {
          top: '48px',
          bottom: 'auto',
        },
      }}
      {...restProps}
    />
  );
};

export const SelectText = forwardRef(SelectTextContainer) as (
  props: SelectTextProps & { ref?: ForwardedRef<HTMLInputElement> },
) => ReturnType<FC<SelectTextProps>>;
