import withTranslation from 'next-translate/withTranslation';

import { Translate } from '@core/constant';
import { EButtonVariant, WithTranslation } from '@core/type';

import { Button } from './Button';
import { ButtonBaseProps } from './interface-button';

const ButtonCloseContainer = ({
  i18n: { t },
  ...restProps
}: WithTranslation & ButtonBaseProps): JSX.Element => {
  return (
    <Button variant={EButtonVariant.OUTLINED} {...restProps}>
      {t('close.title')}
    </Button>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ButtonClose = withTranslation(ButtonCloseContainer, Translate.common.BUTTON);
