import type { ReactNode } from 'react';

import { formContext } from '@core/context';
import { ErrorHandled } from '@core/type';
import { FormHook, FormState } from '@core/type/context';

import { storeHook } from '../helpers';
import { useForm } from '../hook';

export const ProviderFormControl = ({
  children,
  ...restProps
}: {
  error?: ErrorHandled;
  isLoading?: boolean;
  children: ReactNode;
}) => {
  const hooks = storeHook<FormState, FormHook>(restProps, useForm);
  return <formContext.Provider value={hooks}>{children}</formContext.Provider>;
};
