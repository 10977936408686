import { createContext } from 'react';

import type { FormHook, FormState, HookStore } from '@core/type/context';

import { useFnContext, useStoreContext } from '../util';

export const formContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<HookStore<any, FormState, FormHook>>(null);

export const useContextForm = () => {
  return useFnContext(formContext);
};

export function useStoreForm<Selector>(selector: keyof FormState) {
  return useStoreContext<Selector, FormState, FormHook>(selector, formContext);
}
