import { camelize } from 'humps';
import { useEffect } from 'react';
import styled from 'styled-components';

import { useContextForm } from '@core/context';
import { ProviderFormControl } from '@core/provider';
import { ErrorHandled } from '@core/type';

import { FormControlProps } from './interface-form';

const FormStyled = styled.form`
  width: 100%;
  padding: 0;
`;

const FormControlWrapper: <T, R>(
  props: FormControlProps<T, R>,
) => React.ReactElement<FormControlProps<T, R>> = ({
  children,
  handleSubmit,
  onSuccess,
  onError,
  reset,
  trigger,
  onInvalid,
  setError: setErrorForm,
  fn,
  id,
  t,
}): JSX.Element => {
  const { setIsLoading, isLoading, setError } = useContextForm();

  useEffect(() => {
    async function initValidationForm() {
      await trigger();
    }
    void initValidationForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const result = await fn(data);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        reset(data);
        if (onSuccess && result) {
          await onSuccess(result);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if ((e as ErrorHandled)?.errors?.length > 0) {
          (e as ErrorHandled).errors.forEach(({ attribute, message }) => {
            setErrorForm(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
              camelize(attribute as any),
              { type: 'custom', message },
              { shouldFocus: false },
            );
          });
        }
        setError(e as ErrorHandled);
        if (onError) {
          await onError(e as Promise<ErrorHandled>, (e as ErrorHandled)?.errors?.length <= 0);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <FormStyled
      noValidate
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit, onInvalid)}
      aria-label={'formControl'}
      id={id}
    >
      {children}
    </FormStyled>
  );
};

export const FormControl: <T, R>(
  props: FormControlProps<T, R>,
) => React.ReactElement<FormControlProps<T, R>> = (props): JSX.Element => {
  return (
    <ProviderFormControl>
      <FormControlWrapper {...props} />
    </ProviderFormControl>
  );
};
