import { FC } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { EColor, ESize, WithThemeProps } from '@core/type';

import { InputBase } from '../InputBase';
import { InputWrapper } from '../InputWrapper';
import { InputTextWrapperProps } from '../interface-input';

export const InputTextWrapper: FC<InputTextWrapperProps> = styled(InputWrapper)(({
  theme: {
    structure: {
      input: {
        text: {
          wrapper: {
            padding: wrapperPadding,
            paddingWithIcon,
            paddingWithLabel,
            size: sizeVariant,
            ...wrapperProps
          },
          base: inputBaseRules,
        },
      },
    },
    palette,
  },
  multiline,
  withLabel,
  withRightSideIcon,
  size = ESize.MD,
}: InputTextWrapperProps & WithThemeProps) => {
  const padding = withLabel ? paddingWithLabel : wrapperPadding;
  const paddingRight = withRightSideIcon && paddingWithIcon;
  const height = multiline ? 'auto' : sizeVariant[size].height;

  return css`
    ${{ padding, paddingRight, height, ...wrapperProps }};
    background-color: ${palette[EColor.BACKGROUND][EColor.NEUTRAL][EColor.LIGHT]};

    ${InputBase} {
      ${inputBaseRules as CSSObject};
    }
  `;
});
