import { Datetime, EDateFormat, EDateTimezone, ELanguageTags } from '@core/type';

// To remove all white-spaces return by Intl library to a space ' '.
// Error with some IDE
const formatSpace = (date: string): string => {
  return date.replaceAll(/\s+/g, ' ');
};

export const formatDate = (
  date: Datetime,
  locale: ELanguageTags,
  format?: EDateFormat,
  timeZone?: EDateTimezone,
): string => {
  switch (format) {
    case EDateFormat.mmYYYY:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: 'numeric',
        }).format(new Date(date)),
      );
    case EDateFormat.ddMMyyyy:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          timeZone,
        }).format(new Date(date)),
      );
    case EDateFormat.ddMM:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          month: 'short',
          day: 'numeric',
          timeZone,
        }).format(new Date(date)),
      );
    case EDateFormat.yMd:
      return formatSpace(new Intl.DateTimeFormat(locale, { timeZone }).format(new Date(date)));
    case EDateFormat.ddMMyyyyhhmm:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          timeZone,
        }).format(new Date(date)),
      );
    case EDateFormat.MMyyyy:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          month: 'short',
          year: 'numeric',
          timeZone,
        }).format(new Date(date)),
      );
    case EDateFormat.MMMMyyyy:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          month: 'long',
          year: 'numeric',
          timeZone,
        }).format(new Date(date)),
      );
    case EDateFormat.hhmm:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          hour: 'numeric',
          minute: 'numeric',
          timeZone,
        }).format(new Date(date)),
      );
    case EDateFormat.ddMMyyyyhhmm24:
      return formatSpace(
        new Intl.DateTimeFormat(locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          timeZone,
        }).format(new Date(date)),
      ).replaceAll(',', '');
    default:
      return formatSpace(new Intl.DateTimeFormat(locale, { timeZone }).format(new Date(date)));
  }
};

export const formatDatePeriod = (
  from: Datetime,
  to: Datetime,
  locale: ELanguageTags,
  timeZone?: EDateTimezone,
): string => {
  const fromYear = new Date(from).getFullYear();
  const toYear = new Date(to).getFullYear();
  const fromFormat = toYear > fromYear ? EDateFormat.ddMMyyyy : EDateFormat.ddMM;

  const fromDate = formatDate(from, locale, fromFormat, timeZone);
  const toDate = formatDate(to, locale, EDateFormat.ddMMyyyy, timeZone);

  return `${fromDate} - ${toDate}`;
};

export const convertDatetimeToDate = (date: Datetime): Date => new Date(date);

export const convertDateToDatetime = (date: Date): Datetime => date.toISOString();
