import { LabelColorHandler, EColor } from '@core/type';

import { getPaletteHandlers } from './palette';

export const getLabelColorProps: LabelColorHandler = ({ color, palette }) => {
  const { getColor, getTextColor } = getPaletteHandlers(palette);

  if (color === EColor.PRIMARY) {
    return {
      color: getTextColor(EColor.ACCENT),
      backgroundColor: getColor({ commonType: EColor.BLUE, intensity: EColor.A50 }),
    };
  }

  if (color === EColor.SUCCESS) {
    return {
      color: getColor(EColor.SUCCESS),
      backgroundColor: getColor({
        commonType: EColor.GREEN,
        intensity: EColor.R50,
      }),
    };
  }

  if (color === EColor.WARNING) {
    return {
      color: getColor({ commonType: EColor.YELLOW, intensity: EColor.R600 }),
      backgroundColor: getColor({
        commonType: EColor.YELLOW,
        intensity: EColor.R700,
      }),
    };
  }

  if (color === EColor.DANGER) {
    return {
      color: getTextColor(EColor.DANGER),
      backgroundColor: getColor({ commonType: EColor.RED, intensity: EColor.A200 }),
    };
  }

  return {
    color: getTextColor(EColor.ACCENT),
    backgroundColor: getColor({ commonType: EColor.BLUE, intensity: EColor.A50 }),
  };
};
