import { forwardRef, useId } from 'react';

import { InputBackground } from '../InputBackground';
import { InputBase } from '../InputBase';
import { InputBorder } from '../InputBorder';
import { InputLabel } from '../InputLabel';
import { InputTextProps } from '../interface-input';

export const InputTextBase = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      label,
      isError: defaultIsError,
      isSuccess: defaultIsSuccess,
      multiline,
      size,
      field,
      fieldState,
      withRightSideIcon: _withRightSideIcon,
      value: valueProps,
      format,
      isSubmitted,
      ...inputProps
    }: InputTextProps,
    refForward,
  ) => {
    const id = useId();
    const value: string = field?.value ?? valueProps;
    const ref = field?.ref ?? refForward;
    const isError = fieldState
      ? !!fieldState.error && (fieldState.isTouched || isSubmitted)
      : defaultIsError;
    const isSuccess = fieldState
      ? !fieldState.error && (!!field?.value || !!valueProps)
      : defaultIsSuccess;

    const formattedValue = format?.(value) ?? value;

    return (
      <>
        <InputBase
          id={id}
          ref={ref}
          as={multiline ? 'textarea' : 'input'}
          aria-invalid={isError ? 'true' : 'false'}
          {...field}
          {...inputProps}
          value={value && (formattedValue || '')}
        />
        <InputBackground />
        <InputBorder isError={isError} isSuccess={isSuccess} />
        {label && (
          <InputLabel withValue={!!value} htmlFor={id} size={size}>
            {label}
          </InputLabel>
        )}
      </>
    );
  },
);

InputTextBase.displayName = 'InputTextBase';
